import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

import './Landing_02.css';
import projectData from '../../assets/ProjectsData.json'; // Import the JSON file from the assets folder


export default function Landing_02() {
  const [projects, setProjects] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [tooltip, setTooltip] = useState({
    visible: false,
    top: 0,
    left: 0,
    image: ''
  });
  const navigate = useNavigate(); // Initialize the navigate function

  // Cloudflare image URLs (replace the IDs with your actual Cloudflare image IDs)
  const cloudflareImages = {
    thebarn: "https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/ba84fadf-182a-42cd-8df1-726411ca5f00/public",
    Lenguapp: "https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/aff60605-29bd-451c-ce44-6925e8268500/public",
    cvDesign: "https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/6e7a8fed-6a34-4ed2-0c6b-f7c88c497900/public",
    mothercode: "https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/560f8bfa-d33e-4928-96e7-cb91486c1d00/public",
    greenhousecalculator: "https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/afb684fa-566f-451d-bff1-4845adcd4600/public",
    vportfolio: "https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/8fe7aa22-014e-4cbe-3049-cebf7ed03700/public",
    drpepe:"https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/afead8cb-eb4d-4153-0ac8-8e0bad8ba900/public"
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Directly using the imported project data instead of fetching
    const updatedData = projectData.map(project => {
      if (project.image === 'thebarn') project.image = cloudflareImages.thebarn;
      if (project.image === 'Lenguapp') project.image = cloudflareImages.Lenguapp;
      if (project.image === 'cvDesign') project.image = cloudflareImages.cvDesign;
      if (project.image === 'mothercode') project.image = cloudflareImages.mothercode;
      if (project.image === 'greenhousecalculator') project.image = cloudflareImages.greenhousecalculator;
      if (project.image === 'vportfolio') project.image = cloudflareImages.vportfolio;
      if (project.image === 'drpepe') project.image = cloudflareImages.drpepe;

      return project;
    });

    setProjects(updatedData);

  }, []);

  const handleMouseMove = (e, project) => {
    const radius = 250; // Radius of the circular tooltip (30vw diameter / 2)
    
    setTooltip({
      visible: true,
      top: e.clientY - radius, // Center tooltip vertically
      left: e.clientX - radius, // Center tooltip horizontally
      image: project.image
    });
  };

  const handleMouseLeave = () => {
    setTooltip({ ...tooltip, visible: false });
  };
  const handleProjectClick = (project) => {
    navigate(`/project/${project.url_name}`, { state: project }); // Use url_name for the URL
  };
  return (
    <>
      <div className='landing_02-main-container'>
        <div className='title-landing_02'>RECENT PROJECTS</div>
        <div className='landing_02-projects-container'>
          {projects.map((project, index) => (
            <div
              href={project.url || "#"}
              className='landing_02-project-card'
              target="_blank"
              rel="noopener noreferrer"
              key={index}
              onMouseMove={(e) => handleMouseMove(e, project)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleProjectClick(project)} // Navigate to project page on click

            >
              <div className='landing_02-project-info'>
                <img src={project.image} alt={project.name} className='project-image' />
                <h2>{project.name}</h2>
                <p>{project.services}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <a href='/projects' className='landing_02-morework-button'>More Projects</a>

      {tooltip.visible && (
        <div
          className='tooltip-container'
          style={{ top: tooltip.top, left: tooltip.left }}
        >
          <img src={tooltip.image} alt="Project preview" className='tooltip-image' />
        </div>
      )}
    </>
  );
}
