import React, { useEffect, useState } from 'react';
import styles from './ProjectDetails.module.css';

import { useLocation } from 'react-router-dom';
import Header from '../../components/Header/Header';
import FooterMiniMini from '../../components/FooterMiniMini/FooterMiniMini';

// Import images
import coverImageCvDesserts from '../../assets/cover_image_cvdesserts.jpg';
import coverImageDrPepe from '../../assets/cover_image_drpepe.jpg';
import coverImageTheBarn from '../../assets/cover_image_thebarn.jpg';
import coverImageGHCalculator from '../../assets/cover_image_ghcalculator.jpg';

// Import laptop image
import laptopImage from '../../assets/laptop_frame.png'

// Import Videos
import videoGreenHouseCalculator from '../../assets/video_greenhousecalculator.mp4';
import videoTheBarnHero from '../../assets/video_thebarn_hero.mp4';

// Import JSON data
import projectData from '../../assets/ProjectsData.json';

export default function ProjectDetails() {
  const location = useLocation();

  
  const [project, setProject] = useState(null);

  useEffect(() => {
    // Find the project based on the URL
    const projectFound = projectData.find(
      (p) => `/project/${p.url_name}` === location.pathname
    );
    
    setProject(projectFound);
  }, [location.pathname]); // Update when pathname changes

  if (!project) {
    return <div>Project not found</div>;
  }

  // Map URLs to images
  const imageMap = {
    'cv-desserts': coverImageCvDesserts,
    'drpepe-ai': coverImageDrPepe,
    'the-barn': coverImageTheBarn,
    'gh-calculator': coverImageGHCalculator,
  };

  // Map URLs to videos
  const videoMap = {
    'gh-calculator': videoGreenHouseCalculator,
    'the-barn': videoTheBarnHero,
  }

  const coverImage = imageMap[project.url_name]; // Get the correct image based on project data
  const video = videoMap[project.url_name]; // Get the correct video based on project data

  return (
    <div className="project-page">
      <Header />




      <div className={styles.project_details_container}>


        <div  className={styles.project_details_title_box_container}>
          <div className={styles.project_details_title}>{project.name}</div>

          <div className={styles.project_details_options_container}>

            <div className={styles.project_details_boxes}>
              <h6>ROLE / SERVICES</h6>
              <div className={styles.project_details_description}>
                <p>{project.services}</p>
              </div>
            </div>

            <div className={styles.project_details_boxes}>
              <h6>CREDITS</h6>
              <div className={styles.project_details_description}>
                <p>{project.credits}</p>
              </div>
            </div>

            <div className={styles.project_details_boxes}>
              <h6>LOCATION & YEAR</h6>
              <div  className={styles.project_details_description}>
                <p>{project.location} {project.year}</p>
              </div>
            </div>

          </div>
        </div>

        <img
          src={coverImage}
          alt={project.name}
          className={styles.project_details_coverImage}
        />

<div className={styles.project_details_laptopframe_video}>
    <div className={styles.project_details_laptopframe_video_inner}>
        {video && (
          <video autoPlay loop muted className={styles.project_details_video_one}>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        <img
          src={laptopImage}
          alt={project.name}
          className={styles.project_details_laptopImage}
        />
    </div>
</div>


      </div>
        


      <FooterMiniMini />
    </div>
  );
}
