import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './Splash.css'; // Ensure Splash.css includes appropriate styles for your splash animation

const Splash = ({ setShowSplash, path }) => {
  const splashRef = useRef(null);

  // Custom name mappings
  const customNames = {
    'the-barn': 'The Barn',
    'greenhouse-calculator': 'Greenhouse Calculator',
    'drpepe-ai': 'Dr.Pepe AI',
    'cv-desserts': 'CV Desserts',
    // Add more mappings as needed
  };

  useEffect(() => {
    const timeline = gsap.timeline({ onComplete: () => setShowSplash(false) });

    // Ensure splash starts with the correct background color
    gsap.set(splashRef.current, { backgroundColor: '#141414' });

    // Animation to fade in the splash screen from the dark grey color
    timeline.to(splashRef.current, {
      duration: 0.7,
      opacity: 1,
      ease: 'power2.inOut',
    });

    // Animation to withdraw the splash screen from bottom to top
    timeline.to(splashRef.current, {
      duration: 0.7,
      height: 0,
      ease: 'power2.inOut',
      delay: 1,
    });

    return () => {
      timeline.kill();
    };
  }, [setShowSplash]);

  // Handle path display logic
  let displayText = "Welcome";
  
  if (path === "/") {
    displayText = "Welcome";
  } else if (path.startsWith("/project/")) {
    // Extract the project name from the URL (e.g., /project/the-barn -> "the-barn")
    const projectName = path.split("/")[2];

    // Look for a custom name or fall back to default formatting
    if (customNames[projectName]) {
      displayText = customNames[projectName]; // Use custom name if available
    } else {
      // Default formatting: capitalize each word and replace hyphens with spaces
      displayText = projectName
        .replace(/-/g, " ") // Replace hyphens with spaces
        .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
    }
  }

  return (
    <div className="splash" ref={splashRef}>
      {/* Display the processed text */}
      <h1>{displayText}</h1>
    </div>
  );
};

export default Splash;
