import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import styles from './About.module.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

export default function About() {
  const aboutRef = useRef(null);

  // Immediately set opacity to 1 once component mounts
  useEffect(() => {
    gsap.set(aboutRef.current, { opacity: 1 });
  }, []);

  return (
    <>
      <div className={styles.about_MainContainer} ref={aboutRef}>
        <Header />

        <div className={styles.about_containerFirst}>
          <div className={styles.about_firstTitle}>Driving brand success</div>
          <div className={styles.about_firstTitle}> in the digital realm</div>
          <div className={styles.about_line}>
            <div className={styles.about_circleOverline}></div>
          </div>
        </div>

        <div className={styles.about_containerSecond}>
          <div className={styles.about_containerTextfirstAbout}>
            <div className={styles.about_containerFlechitaText}>
              <div className={styles.about_containerDoubleText}>
                <div>
                I craft personalized solutions for clients around the globe, striving for excellence in every project and always emphasizing quality.
                </div>
                <div className={styles.about_secondtextShort}>Always Learning</div>
              </div>
            </div>
          </div>

          <div className={styles.about_containerImageAbout}>
            <img src="https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/68a31391-e578-40f4-a0d8-1b8611b7c100/public" alt="Victor in Albania" />
          </div>
        </div>

        <div className={styles.about_containerThird}>
          <div className={styles.about_thirdTitle}> I’m here to assist with...</div>

          <div className={styles.about_containerOptions}>
            <div className={styles.about_boxesAbout}>
              <h2>Design</h2>
              <p>
              My expertise in website design and development ensures user-centric digital experiences that seamlessly blend functionality with exceptional UI/UX design, guided by insights from the Nielsen Norman Group.              </p>
            </div>
            <div className={styles.about_boxesAbout}>
              <h2>Development</h2>
              <p>
              Building scalable websites from the ground up with a focus on micro animations, I use a MERN stack with Cloudflare and Cloud Firestore, integrating GSAP and Three.js for seamless design.
              </p>
            </div>
            <div className={styles.about_boxesAbout}>
              <h2>The bundle</h2>
              <p>
              From concept to launch, I deliver complete websites that stand out. My strong design sense and development skills come together to create exceptional projects.about_
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
