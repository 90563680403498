import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import './Projects.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import projectData from '../../assets/ProjectsData.json'; // Import the JSON file from the assets folder


gsap.registerPlugin(ScrollTrigger);

export default function Projects() {
  const [filter, setFilter] = useState('All');
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [tooltip, setTooltip] = useState({
    visible: false,
    top: 0,
    left: 0,
    image: ''
  });
  const projectRef = useRef(null);
  const navigate = useNavigate(); // Initialize the navigate function

  // Cloudflare image URLs (replace the IDs with your actual Cloudflare image IDs)
  const cloudflareImages = {
    thebarn: "https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/ba84fadf-182a-42cd-8df1-726411ca5f00/public",
    Lenguapp: "https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/aff60605-29bd-451c-ce44-6925e8268500/public",
    cvDesign: "https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/6e7a8fed-6a34-4ed2-0c6b-f7c88c497900/public",
    mothercode: "https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/560f8bfa-d33e-4928-96e7-cb91486c1d00/public",
    greenhousecalculator: "https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/afb684fa-566f-451d-bff1-4845adcd4600/public",
    vportfolio: "https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/8fe7aa22-014e-4cbe-3049-cebf7ed03700/public",
    drpepe:"https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/afead8cb-eb4d-4153-0ac8-8e0bad8ba900/public"

  };


  useEffect(() => {
    // Directly using the imported project data instead of fetching
    const updatedData = projectData.map(project => {
      if (project.image === 'thebarn') project.image = cloudflareImages.thebarn;
      if (project.image === 'Lenguapp') project.image = cloudflareImages.Lenguapp;
      if (project.image === 'cvDesign') project.image = cloudflareImages.cvDesign;
      if (project.image === 'mothercode') project.image = cloudflareImages.mothercode;
      if (project.image === 'greenhousecalculator') project.image = cloudflareImages.greenhousecalculator;
      if (project.image === 'vportfolio') project.image = cloudflareImages.vportfolio;
      if (project.image === 'drpepe') project.image = cloudflareImages.drpepe;

      return project;
    });

    setProjects(updatedData);
    setFilteredProjects(updatedData);

  }, []);

  useEffect(() => {
    let filtered = projects;
    if (filter === 'Design') {
      filtered = projects.filter(project => project.services.includes('Design'));
    } else if (filter === 'Development') {
      filtered = projects.filter(project => project.services.includes('Development'));
    }
    setFilteredProjects(filtered);
  }, [filter, projects]);

  useEffect(() => {
    gsap.set(projectRef.current, { opacity: 1 });
  }, []);

  const handleMouseMove = (e, project) => {
    const radius = 250; // Adjust the radius as needed

    setTooltip({
      visible: true,
      top: e.clientY - radius, // Center tooltip vertically
      left: e.clientX - radius, // Center tooltip horizontally
      image: project.image
    });
  };

  const handleMouseLeave = () => {
    setTooltip({ ...tooltip, visible: false });
  };
  const handleProjectClick = (project) => {
    navigate(`/project/${project.url_name}`, { state: project }); // Use url_name for the URL
  };
  

  return (
    <div className='projects-main-container' ref={projectRef}>
      <Header />
      <div className='projects-title-container'>
        <h2>Building next level</h2>
        <h2>digital experiences</h2>
      </div>

      <div className='projects-filter-container'>
        <div className='projects-filters'>
          <button className={filter === 'All' ? 'active' : ''} onClick={() => setFilter('All')}>All</button>
          <button className={filter === 'Design' ? 'active' : ''} onClick={() => setFilter('Design')}>Design</button>
          <button className={filter === 'Development' ? 'active' : ''} onClick={() => setFilter('Development')}>Development</button>
        </div>
        <div className='projects-filter-view'>
          <button>List</button>
          <button>Square</button>
        </div>
      </div>

      <div className='container-mvp'>
        <div className='description-row-container'>
          <div className='description-client-container'>CLIENT</div>
          <div className='description-location-container'>LOCATION</div>
          <div className='description-servi-year-container'>
            <div className='description-services-container'>SERVICES</div>
            <div className='description-year-container'>YEAR</div>
          </div>
        </div>

        {filteredProjects.map((project, index) => (
          <div   
             
               className='section-mvp card' key={index}
               target="_blank"
               rel="noopener noreferrer"
               onClick={() => handleProjectClick(project)} // Navigate to project page on click
               onMouseMove={(e) => handleMouseMove(e, project)}
               onMouseLeave={handleMouseLeave}

           
          >
            <div className='card-text-container'>
              <div className='projects-title'>
                <h2>{project.name}</h2>
              </div>
              <div className='projects-location'>
                <h2>{project.location}</h2>
              </div>
              <div className='servi-year-container'>
                <div className='projects-services'>
                  <h2>{project.services}</h2>
                </div>
                <div className='projects-year'>
                  <h2>{project.year}</h2>
                </div>
              </div>
            </div>


          </div>
        ))}
      </div>
      <Footer />

      {tooltip.visible && (
        <div className='project- tooltip-container' style={{ top: tooltip.top, left: tooltip.left }}>
          <img src={tooltip.image} alt="Project preview" className='project-tooltip-image' />
        </div>
      )}
    </div>
  );
}
